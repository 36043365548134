import React, { useContext } from 'react';
import { ButtonType, ContainedButton} from 'atoms/Buttons';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import CONSTANTS from '../Constants/Constants';
import WELCOME_BACK_COSTANTS from '../Constants/Constants';
import { UserOrderContext } from 'context/context';
import { getValueOrDefaultObject, isEmptyEvery } from 'common/utilities/utils';
import { DISPOSITIONNEW } from 'common/constants/SharedConstants';
import { keepOrderAnalyticFn } from 'organisms/ReorderComponent/ReorderUtils/ReorderUtils';
import START_ORDER_CONSTANTS from 'organisms/StartOrderComponent/Constants/startOrderConstants';

const WelcomeBackComponent = props => {
  const { setIsModalOpen, onCancel } = props;

  const { YES_KEEP_IT, ORDER_FOR, WELCOME_BACK, CHANGE_BTN, PREVIOUS_ORDER_SETTING_MSG } = CONSTANTS;

  const { userOrderState, userOrderStateDispatch } = useContext(UserOrderContext);
  const dispositionNew = isEmptyEvery(userOrderState?.dispositionNew)
    ? JSON.parse(localStorage.getItem(DISPOSITIONNEW))
    : userOrderState.dispositionNew;

  const { type: dispositionType = '', store } = dispositionNew || {};
  const { addressLines, city, state, pinCode } = getValueOrDefaultObject(store?.addresses[0]?.address);

  const onChangeSettingHandler = event => {
    setIsModalOpen(false);
    event.preventDefault();
    event.stopPropagation();
    userOrderStateDispatch({ type: START_ORDER_CONSTANTS.DISPOSITION_DATA, value: { showDisposition: true } });
    keepOrderAnalyticFn(WELCOME_BACK_COSTANTS.CANCEL);
  };

  return (
    <div className='welcome-container'>
      <div className='welcome-back-heading'>
        <p className='mb-0'>
          {translateWithI18Next(WELCOME_BACK)}
          {'!'}
        </p>
        <p>{translateWithI18Next(PREVIOUS_ORDER_SETTING_MSG)}</p>
      </div>
      <div className='order-details-section'>
        <p className='mb-0 order-type'>
          {translateWithI18Next(ORDER_FOR)} {dispositionType.toLowerCase()}
        </p>
        <p className='mb-0'>{`${addressLines?.join(' ')}, ${city}`}</p>
        <p>{`${state}, ${pinCode}`}</p>
      </div>
      <div className='buttonItems'>
        <ContainedButton
          data-testid='welcome-back-change-button'
          variant={ButtonType.tertiary}
          onClickHandler={onChangeSettingHandler}
        >
          {translateWithI18Next(CHANGE_BTN)}
        </ContainedButton>
        <ContainedButton
          data-testid='yes-keep-it-btn'
          variant={ButtonType.secondary}
          onClickHandler={onCancel}
        >
          {translateWithI18Next(YES_KEEP_IT)}
        </ContainedButton>
      </div>
    </div>
  );
};

export default WelcomeBackComponent;
