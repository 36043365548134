import React, { useEffect } from 'react';
import kfcCup from '../../../styles/staticAssets/images/kfcCup.svg';
import kfc_bucket_mob from '../../../styles/staticAssets/images/kfc_bucket_mob.svg';
import { ReactSVG } from 'react-svg';
import { ButtonComp } from 'atoms/Buttons';
import NOT_FOUND_404_ERROR from '../Constants/NotFoundErrorConstants';
import { isMobileDevice } from 'common/utilities/utils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
export const NotFoundError = props => {
  const { ERROR_CODE, THERE_NO_FRIED_TEXT, CHICKEN_PAGE_TEXT, TAKE_ME_HOME_TEXT } = NOT_FOUND_404_ERROR;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const gotToHome = () => {
    props.history.push({
      pathname: '/',
    });
  };

  return (
    <>
      <div className='not-found-container'>
        <div className='not-found-kfc-bucket-part'>
          <ReactSVG src={isMobileDevice(window.innerWidth) ? kfc_bucket_mob : kfcCup} />
        </div>
        <div className='not-found-error-part'>
          <p className='not-found-error-text red-text'>{ERROR_CODE}</p>
          <p className='not-found-error-text'>{THERE_NO_FRIED_TEXT}</p>
          <p className='not-found-error-text'>{CHICKEN_PAGE_TEXT}</p>
          <ButtonComp data-testid='take-me-homeid' className='take-me-home-button' onClick={gotToHome}>
          {translateWithI18Next(TAKE_ME_HOME_TEXT)}
          </ButtonComp>
        </div>
      </div>
    </>
  );
};
