/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* Geohash encoding/decoding and associated functions   (c) Chris Veness 2014-2019 / MIT Licence  */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
import SEARCH_STORE_CONSTANTS from 'organisms/SearchStore/AU/Constants/SearchStoreConstants';
const base32 = '0123456789bcdefghjkmnpqrstuvwxyz'; // (geohash-specific) Base32 map
let dataFromServer = [],
  geoChecker = [],
  geoToSearch = [],
  lat,
  lon,
  googleAPIResponseCallBack,
  allSortedStoresDataCallBack,
  validateStoreListCallBack,
  getAllSortedFavStoresData,
  userFavStoreData = [],
  market,
  dispositionType,
  getStoreUrl = '',
  originAddressText = '',
  allPromise = [];

/**
 * Geohash: Gustavo Niemeyer’s geocoding system.
 */
class Geohash {
  /**
   * Encodes latitude/longitude to geohash, either to specified precision or to automatically
   * evaluated precision.
   *
   * @param   {number} lat - Latitude in degrees.
   * @param   {number} lon - Longitude in degrees.
   * @param   {number} [precision] - Number of characters in resulting geohash.
   * @returns {string} Geohash of supplied latitude/longitude.
   * @throws  Invalid geohash.
   *
   * @example
   *     const geohash = Geohash.encode(52.205, 0.119, 7); // => 'u120fxw'
   */
  static encode(lat, lon, precision) {
    // infer precision?
    if (typeof precision == 'undefined') {
      // refine geohash until it matches precision of supplied lat/lon
      for (let p = 1; p <= 12; p++) {
        const hash = Geohash.encode(lat, lon, p);
        const posn = Geohash.decode(hash);
        if (posn.lat === lat && posn.lon === lon) {
          return hash;
        }
      }
      precision = 12; // set to maximum
    }

    lat = Number(lat);
    lon = Number(lon);
    precision = Number(precision);

    if (isNaN(lat) || isNaN(lon) || isNaN(precision)) {
      throw new Error('Invalid geohash');
    }

    let idx = 0; // index into base32 map
    let bit = 0; // each char holds 5 bits
    let evenBit = true;
    let geohash = '';

    let latMin = -90,
      latMax = 90;
    let lonMin = -180,
      lonMax = 180;

    while (geohash.length < precision) {
      if (evenBit) {
        // bisect E-W longitude
        const lonMid = (lonMin + lonMax) / 2;
        if (lon >= lonMid) {
          idx = idx * 2 + 1;
          lonMin = lonMid;
        } else {
          idx = idx * 2;
          lonMax = lonMid;
        }
      } else {
        // bisect N-S latitude
        const latMid = (latMin + latMax) / 2;
        if (lat >= latMid) {
          idx = idx * 2 + 1;
          latMin = latMid;
        } else {
          idx = idx * 2;
          latMax = latMid;
        }
      }
      evenBit = !evenBit;

      if (++bit === 5) {
        // 5 bits gives us a character: append it and start over
        geohash += base32.charAt(idx);
        bit = 0;
        idx = 0;
      }
    }

    return geohash;
  }

  /**
   * Decode geohash to latitude/longitude (location is approximate centre of geohash cell,
   *     to reasonable precision).
   *
   * @param   {string} geohash - Geohash string to be converted to latitude/longitude.
   * @returns {{lat:number, lon:number}} (Center of) geohashed location.
   * @throws  Invalid geohash.
   *
   * @example
   *     const latlon = Geohash.decode('u120fxw'); // => { lat: 52.205, lon: 0.1188 }
   */
  static decode(geohash) {
    const bounds = Geohash.bounds(geohash); // <-- the hard work
    // now just determine the centre of the cell...

    const latMin = bounds.sw.lat,
      lonMin = bounds.sw.lon;
    const latMax = bounds.ne.lat,
      lonMax = bounds.ne.lon;

    // cell centre
    let lat = (latMin + latMax) / 2;
    let lon = (lonMin + lonMax) / 2;

    // round to close to centre without excessive precision: ⌊2-log10(Δ°)⌋ decimal places
    lat = lat.toFixed(Math.floor(2 - Math.log(latMax - latMin) / Math.LN10));
    lon = lon.toFixed(Math.floor(2 - Math.log(lonMax - lonMin) / Math.LN10));

    return { lat: Number(lat), lon: Number(lon) };
  }
}

/**
 * <p>Represents a point on the surface of a sphere. (The Earth is almost
 * spherical.)</p>
 *
 * <p>To create an instance, call one of the static methods fromDegrees() or
 * fromRadians().</p>
 *
 * <p>This code was originally published at
 * <a href="http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates#Java">
 * http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates#Java</a>.</p>
 *
 * @author Jan Philip Matuschek
 * @version 22 September 2010
 */

class GeoLocation {
  constructor() {
    this.radLat = 0; // latitude in radians
    this.radLon = 0; // longitude in radians

    this.degLat = 0; // latitude in degrees
    this.degLon = 0; // longitude in degrees

    this.MIN_LAT = -90 * (Math.PI / 180); // -PI/2
    this.MAX_LAT = 90 * (Math.PI / 180); //  PI/2
    this.MIN_LON = -180 * (Math.PI / 180); // -PI
    this.MAX_LON = 180 * (Math.PI / 180); //  PI
  }

  /**
   * @param latitude the latitude, in degrees.
   * @param longitude the longitude, in degrees.
   */
  static fromDegrees(latitude, longitude) {
    latitude = Number(latitude);
    longitude = Number(longitude);
    let result = new GeoLocation();
    result.radLat = latitude * (Math.PI / 180);
    result.radLon = longitude * (Math.PI / 180);
    result.degLat = latitude;
    result.degLon = longitude;
    result.checkBounds();
    return result;
  }

  /**
   * @param latitude the latitude, in radians.
   * @param longitude the longitude, in radians.
   */
  static fromRadians(latitude, longitude) {
    let result = new GeoLocation();
    result.radLat = latitude;
    result.radLon = longitude;
    result.degLat = latitude * (180 / Math.PI);
    result.degLon = longitude * (180 / Math.PI);
    result.checkBounds();
    return result;
  }

  checkBounds() {
    if (
      this.radLat < this.MIN_LAT ||
      this.radLat > this.MAX_LAT ||
      this.radLon < this.MIN_LON ||
      this.radLon > this.MAX_LON
    ) {
      throw 'Invalid bounds';
    }
  }

  /**
   * @return the latitude, in degrees.
   */
  getLatitudeInDegrees() {
    return this.degLat;
  }

  /**
   * @return the longitude, in degrees.
   */
  getLongitudeInDegrees() {
    return this.degLon;
  }

  /**
   * @return the latitude, in radians.
   */
  getLatitudeInRadians() {
    return this.radLat;
  }

  /**
   * @return the longitude, in radians.
   */
  getLongitudeInRadians() {
    return this.radLon;
  }

  /**
   * Computes the great circle distance between this GeoLocation instance
   * and the location argument.
   * @param radius the radius of the sphere, e.g. the average radius for a
   * spherical approximation of the figure of the Earth is approximately
   * 6371.01 kilometers.
   * @return the distance, measured in the same unit as the radius
   * argument.
   */
  distanceTo(location, radius) {
    return (
      Math.acos(
        Math.sin(this.radLat) * Math.sin(location.radLat) +
          Math.cos(this.radLat) * Math.cos(location.radLat) * Math.cos(this.radLon - location.radLon),
      ) * radius
    );
  }

  /**
   * <p>Computes the bounding coordinates of all points on the surface
   * of a sphere that have a great circle distance to the point represented
   * by this GeoLocation instance that is less or equal to the distance
   * argument.</p>
   * <p>For more information about the formulae used in this method visit
   * <a href="http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates">
   * http://JanMatuschek.de/LatitudeLongitudeBoundingCoordinates</a>.</p>
   * @param distance the distance from the point represented by this
   * GeoLocation instance. Must me measured in the same unit as the radius
   * argument.
   * @param radius the radius of the sphere, e.g. the average radius for a
   * spherical approximation of the figure of the Earth is approximately
   * 6371.01 kilometers.
   * @return an array of two GeoLocation objects such that:<ul>
   * <li>The latitude of any point within the specified distance is greater
   * or equal to the latitude of the first array element and smaller or
   * equal to the latitude of the second array element.</li>
   * <li>If the longitude of the first array element is smaller or equal to
   * the longitude of the second element, then
   * the longitude of any point within the specified distance is greater
   * or equal to the longitude of the first array element and smaller or
   * equal to the longitude of the second array element.</li>
   * <li>If the longitude of the first array element is greater than the
   * longitude of the second element (this is the case if the 180th
   * meridian is within the distance), then
   * the longitude of any point within the specified distance is greater
   * or equal to the longitude of the first array element
   * <strong>or</strong> smaller or equal to the longitude of the second
   * array element.</li>
   * </ul>
   */
  boundingCoordinates(distance, radius) {
    // angular distance in radians on a great circle
    let radDist = distance / radius;

    let minLat = this.radLat - radDist;
    let maxLat = this.radLat + radDist;

    var minLon, maxLon;
    if (minLat > this.MIN_LAT && maxLat < this.MAX_LAT) {
      let deltaLon = Math.asin(Math.sin(radDist) / Math.cos(this.radLat));
      minLon = this.radLon - deltaLon;
      if (minLon < this.MIN_LON) {
        minLon += 2 * Math.PI;
      }
      maxLon = this.radLon + deltaLon;
      if (maxLon > this.MAX_LON) {
        maxLon -= 2 * Math.PI;
      }
    } else {
      // a pole is within the distance
      minLat = Math.max(minLat, this.MIN_LAT);
      maxLat = Math.min(maxLat, this.MAX_LAT);
      minLon = this.MIN_LON;
      maxLon = this.MAX_LON;
    }

    let retVal = [];
    retVal.push(GeoLocation.fromRadians(minLat, minLon));
    retVal.push(GeoLocation.fromRadians(maxLat, maxLon));

    return retVal;
  }
}

/**
 * Method to calculate user's favourite location distance
 */
export const calculateStoreDistance = (userLat, userLong, favouriteStores, cb) => {
  lat = userLat;
  lon = userLong;
  userFavStoreData = favouriteStores;
  getAllSortedFavStoresData = cb;
  if (userFavStoreData.length > 0) {
    let allFavStoreLocations = userFavStoreData.map(store => {
      const { latitude, longitude } = store && store.storeBasic.location;
      return { lat: latitude, lng: longitude };
    });

    let startOrigin = [{ lat: userLat, lng: userLong }];
    var service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [...startOrigin].slice(0, 24),
        destinations: [...allFavStoreLocations].slice(0, 24),
        travelMode: window.google.maps.TravelMode.DRIVING,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      collectAllFavoriteDistance,
    );
  } else {
    getAllSortedFavStoresData([], '');
  }
};
export const collectAllFavoriteDistance = (response, status) => {
  let allStoreWithDistanceInfo = [];
  let allStoreDistance = [];
  allStoreDistance = response && response.rows && response.rows[0].elements.map(storeDistance => storeDistance);
  let originAddressText = (response && response.originAddresses && response.originAddresses[0]) || '';
  allStoreWithDistanceInfo = userFavStoreData.map((store, index) => {
    return { ...store, ...allStoreDistance[index] };
  });
  getAllSortedFavStoresData(allStoreWithDistanceInfo, originAddressText);
};

/**
 * Method to calculate user's geo hash position
 * @param {*} userLat - represents user's lat value
 * @param {*} userLong - represents user's lon value
 */
export const calculateGH = (
  userLat,
  userLong,
  tenantID,
  tenantSessionID,
  tenantRequestId,
  currentMarket,
  dispositionMethod,
  getStoreEndPoint,
  cb,
  storeListCb,
) => {
  allSortedStoresDataCallBack = cb;
  validateStoreListCallBack = storeListCb;
  dataFromServer = [];
  geoChecker = [];
  geoToSearch = [];
  lat = userLat;
  lon = userLong;
  market = currentMarket;
  dispositionType = dispositionMethod;
  getStoreUrl = getStoreEndPoint;
  const bsPoint = GeoLocation.fromDegrees(lat, lon);
  const arr = bsPoint.boundingCoordinates(5, 6371);
  const gh1 = Geohash.encode(arr[0].getLatitudeInDegrees(), arr[0].getLongitudeInDegrees(), 4);
  const gh2 = Geohash.encode(arr[1].getLatitudeInDegrees(), arr[1].getLongitudeInDegrees(), 4);
  const gh3 = Geohash.encode(arr[0].getLatitudeInDegrees(), arr[1].getLongitudeInDegrees(), 4);
  const gh4 = Geohash.encode(arr[1].getLatitudeInDegrees(), arr[0].getLongitudeInDegrees(), 4);
  geoToSearch.push(gh1);
  !geoToSearch.includes(gh2) && geoToSearch.push(gh2);
  !geoToSearch.includes(gh3) && geoToSearch.push(gh3);
  !geoToSearch.includes(gh4) && geoToSearch.push(gh4);
  getData(geoToSearch, tenantID, tenantSessionID, tenantRequestId); // call end point  with hash values
};

/**
 * Method to call end points depending on number of geo hash values
 * @param {*} allItem
 */
const getData = (allItem, tenantID, tenantSessionID, tenantRequestId) => {
  const requests = allItem.map(item => {
    geoChecker.push(item);
    return fetch(`${getStoreUrl}${item}`);
  });
  Promise.allSettled(requests)
    .then(responses => {
      allPromise = [];
      responses.forEach((response, index, array) => {
        if (response.status === SEARCH_STORE_CONSTANTS.FULFILLED_TEXT && response.value.status === 200) {
          validateStoreListCallBack(false);
          if (index === array.length - 1) {
            addAllData(response.value.json(), true);
          } else {
            addAllData(response.value.json());
          }
        } else {
          validateStoreListCallBack(true);
        }
      });
    })
    .catch(err => console.log(err));
};
/**
 * Method to populate all stored data from end point
 * @param {*} promise for all fetch requests
 */
const addAllData = (promise, isLastItem) => {
  allPromise.push(promise);
  isLastItem &&
    Promise.all(allPromise).then(responseArr => {
      responseArr.forEach(data => {
        dataFromServer.push(...data);
      });

      chooseMarketApproach();
    });
  if (dataFromServer.length === 0) {
    allSortedStoresDataCallBack([], '');
  }
};

/**
 * Method to choose which market approach to choose : INDIA / NON - INDIA
 */
const chooseMarketApproach = () => {
  if (market === SEARCH_STORE_CONSTANTS.COUNTRY_CODE_INDIA) {
    // for India market
    checkAndProceedIndiaMarket();
  } else {
    // for non - India market
    checkAndProceed();
  }
};

/**
 * Method to sort stores depending to disposition type
 */
const checkAndProceedIndiaMarket = () => {
  const allStoreLocations = dataFromServer.map(store => {
    const { latitude, longitude } = store && store.location;
    return { lat: latitude, lng: longitude };
  });
  // user's addess origin, store address destination
  if (
    dispositionType.toUpperCase() === SEARCH_STORE_CONSTANTS.TYPE_TAKE_WAY ||
    dispositionType.toUpperCase() === SEARCH_STORE_CONSTANTS.DINE_IN
  ) {
    getDistanceFromGoogleMatrix([{ lat: lat, lng: lon }], allStoreLocations);
  } else if (dispositionType.toUpperCase() === SEARCH_STORE_CONSTANTS.TYPE_DELIVERY) {
    // store addess origin, user's address destination
    getDistanceFromGoogleMatrix(allStoreLocations, [{ lat: lat, lng: lon }]);
  }
};
/**
 * Method to get distance from origin(s) to destion(s)
 * @param {*} startOrigin - start origin user's / store
 * @param {*} destination - destination user's / store
 */
export const getDistanceFromGoogleMatrix = (startOrigin, destination, callback) => {
  var service = new window.google.maps.DistanceMatrixService();
  service.getDistanceMatrix(
    {
      origins: [...startOrigin].slice(0, 24),
      destinations: [...destination].slice(0, 24),
      travelMode: window.google.maps.TravelMode.DRIVING,
      unitSystem: window.google.maps.UnitSystem.METRIC,
      avoidHighways: false,
      avoidTolls: false,
    },
    collectAllDistance,
  );
  googleAPIResponseCallBack = callback;
};

/**
 * Method to push distance to every store object
 * @param {*} response
 * @param {*} status
 */
const collectAllDistance = (response, status) => {
  if (status === SEARCH_STORE_CONSTANTS.OK_STATUS) {
    let allStoreDistance = [];
    let elements = response && response.rows && response.rows.map(element => element);
    allStoreDistance = elements?.map(obj => {
      return obj.elements[0];
    });
    originAddressText = (response && response.originAddresses) || [];

    googleAPIResponseCallBack({ allStoreDistance, originAddressText });
  }
};


/**
 * method to check delivery depending on all store data - non - india market
 */
const checkAndProceed = () => {
  const allStoreDetails = [];
  if (geoChecker.length === geoToSearch.length) {
    dataFromServer.sort(compare);
    for (const data of dataFromServer) {
      if (checkDelivery(lat, lon, data.Polygon)) {
        allStoreDetails.push(data);
      }
    }
    allSortedStoresDataCallBack(allStoreDetails, originAddressText);
  }
};

/**
 * Method to sort stores for non-india market
 * @param {*} a - item 1
 * @param {*} b - item 2
 */
const compare = (a, b) => {
  const d1 = calculateDistance(lat, lon, a.Location.Latitude, a.Location.Longitude);
  const d2 = calculateDistance(lat, lon, b.Location.Latitude, b.Location.Longitude);
  return d1 - d2;
};

/**
 * Method to calculate distance for non - india market
 * @param {*} lat1 - user's lat
 * @param {*} lon1 - user's lon
 * @param {*} lat2 - store lat
 * @param {*} lon2 - store lon
 */
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d / 1000;
};

/**
 * Method to calculate delivery - non develivery market
 * @param {*} lat
 * @param {*} lon
 * @param {*} polygon
 */
const checkDelivery = (latitude, longitude, polygon) => {
  if (polygon) {
    const triangleCoords = [];
    const pts = polygon.split(' ');
    if (pts && pts.length > 0) {
      for (const pt of pts) {
        if (pt) {
          let latnLong = pt.split('|');
          triangleCoords.push({ lat: Number(latnLong[0]), lng: Number(latnLong[1]) });
        }
      }
      if (triangleCoords.length > 0) {
        const bermudaTriangle = new window.google.maps.Polygon({ paths: triangleCoords });
        if (
          window.google.maps.geometry.poly.containsLocation(
            new window.google.maps.LatLng(latitude, longitude),
            bermudaTriangle,
          )
        ) {
          return true;
        }
      }
    }
  }
  return false;
};
export const filterStoreBasedOnPolygon = (polygonCoordList, deliveryAddressLat, deliveryAddressLong) => {
  if (polygonCoordList && polygonCoordList.length > 0 && deliveryAddressLat && deliveryAddressLong) {
    const polygon = new window.google.maps.Polygon({ paths: polygonCoordList });
    return (
      polygon &&
      window.google.maps.geometry.poly.containsLocation(
        new window.google.maps.LatLng(deliveryAddressLat, deliveryAddressLong),
        polygon,
      )
    ) 
  }
  return false;
};
